import React, {useState} from "react";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { HOTSPOT_TYPES } from "../../constants/options";
import { reqSetActiveLocation } from "../../reduxs/category/action";
import { reqSetExploreModal } from "../../reduxs/explore-modal/action";
import { reqSetIsShowGalleryModal, reqSetIsShowHotspotModal, reqSetSelectedHotspot } from "../../reduxs/home/action";
import './index.scss';

const GalleryPopup = () => {
  const galleryRef = useRef();
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.exploreModal.modal);
  const [sliderActive, setSliderActive] = useState(0);

  // const hotspot = useSelector((state) => state.exploreModal.selectedHotspot);
  // const locations = useSelector((state) => state.scene.locations);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    afterChange: function(index) {
      setSliderActive(index);
      console.log(
        `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
      );
    }
  };

  const handleClosePopup = () => {
    dispatch(reqSetIsShowGalleryModal(false));
    dispatch(reqSetExploreModal());
    dispatch(reqSetActiveLocation(''));
    dispatch(reqSetSelectedHotspot(null));
  }

  const handleOpenVirtualTour = () => {
    dispatch(reqSetIsShowGalleryModal(false));
    dispatch(reqSetExploreModal(modal.virtual_modal));
    dispatch(reqSetIsShowHotspotModal(true));
  }

  const renderBtnNextPrev = () => {
    const color = "rgba(0,0,0,0.1)";
    const icInActive = "/icons/arrow_inActive.svg";
    const icActive = "/icons/arrow_active.svg";

    // let data = hotspot;
    // if (data == undefined) {
    //   for (const key in locations) {
    //     let location = locations[key];
    //     if (location.link == modal?.id) {
    //       data = location;
    //       break;
    //     }
    //   }
    // }

    // if (data != undefined) {
    //   if (data.color == "#000030") {
    //     color = "#FFFFFF";
    //   } else if (data.color == "#84B1BB") {
    //     color = "#84B1BB";
    //     iconLeft = "/icons/left_arrow_white.svg";
    //     iconRight = "/icons/right_arrow_white.svg";
    //   } else if (data.color == "#F0C99E") {
    //     color = "#F0C99E";
    //     iconLeft = "/icons/left_arrow_white.svg";
    //     iconRight = "/icons/right_arrow_white.svg";
    //   } else {
    //     color = data.color;
    //     iconLeft = "/icons/left_arrow_white.svg";
    //     iconRight = "/icons/right_arrow_white.svg";
    //   }
    // }

    if (modal?.type === HOTSPOT_TYPES.GALLERY_OF_IMAGES)
      return (
        <div className='wrap-arrow-slider'>
          {
            (modal?.image_links || []).map((i, idx) => {
              return(
                <div key={`arrow-${idx}`} className="icon-arrow" onClick={() => galleryRef.current.slickNext()}>
                  <img className={`img-fluid ${idx % 2 === 0 && 'img-rotate'}`} src={sliderActive === idx ? icActive : icInActive} alt="" />
                </div>
              )
            })
          }
          {/*<div className="wrap-btn-next" style={{backgroundColor: `${color}`}} onClick={() => galleryRef.current.slickNext()}>*/}
          {/*  <img className="btn-next img-fluid" src={iconRight} alt="" />*/}
          {/*</div>*/}
          {/*<div className="wrap-btn-prev" style={{backgroundColor: `${color}`}} onClick={() => galleryRef.current.slickPrev()}>*/}
          {/*  <img className="btn-prev img-fluid" src={iconLeft} alt="" />*/}
          {/*</div>*/}
        </div>
    )
  }

  const renderVirtualBtn = () => {
    if (modal?.virtual_modal) {
      return (
        <div onClick={handleOpenVirtualTour} className="btn-explore">
          <span>
            VIRTUAL EXPLORATION
          </span>
          <img src="./icons/arrow_gray.svg" alt="" />
        </div>
      );
    }
  }


  // let color = "#ff0000";
  // let icon = "close-icon-white";

  // let data = hotspot;
  // if (data == undefined) {
  //   for (const key in locations) {
  //     let location = locations[key];
  //     if (location.link == modal?.id) {
  //       data = location;
  //       break;
  //     }
  //   }
  // }

  // if (data != undefined) {
  //   if (data.color == "#000030") {
  //     color = "#FFFFFF";
  //     icon = "/icons/close-icon-black.svg";
  //   } else if (data.color == "#84B1BB") {
  //     color = "#84B1BB";
  //     icon = "/icons/close-icon-white.svg";
  //   } else if (data.color == "#F0C99E") {
  //     color = "#F0C99E";
  //     icon = "/icons/close-icon-white.svg";
  //   } else {
  //     color = data.color;
  //     icon = "/icons/close-icon-white.svg";
  //   }
  // }

  return (
    <>
      <div className="bg-trim" />
      <div className="wrap-gallery-popup show">
        <div className="wrap-popup-content h-100">
          <div className="popup-content">
            <div className='content-left'>
                <span className='title-content'>
                  {modal?.title}
                </span>
                <span className='description-content'>
                  {modal?.description}
                </span>
              {renderVirtualBtn()}
              <div className='pattern'>
                <img  src="./icons/pattern.svg" alt="" />
              </div>
            </div>
            <div className='content-right'>
                  <Slider ref={galleryRef} className="wrap-gallery-image-popup" {...settings}>
                    {
                      (modal?.image_links || []).map((item, index) => {
                        return (
                          <div key={index} className="w-100 h-100 image-popup-item text-center">
                            <img className="h-100 w-100" key={index} src={item} alt="" />
                          </div>
                        )
                      })
                    }
                  </Slider>
              {renderBtnNextPrev()}
            </div>
            {/*<div className="popup-header">*/}
            {/*  <h3 className="title" style={{color: `${color}`}}>{modal?.title}</h3>*/}
            {/*</div>*/}
            {/*<div className="row popup-body m-0">*/}
            {/*  <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 description">*/}
            {/*    <div className="description-text">{modal?.description}</div>*/}
            {/*    {renderVirtualBtn()}*/}
            {/*  </div>*/}
            {/*  <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12 images p-0">*/}
            {/*    <Slider ref={galleryRef} className="wrap-gallery-image-popup" {...settings}>*/}
            {/*      {*/}
            {/*        (modal?.image_links || []).map((item, index) => {*/}
            {/*          return (*/}
            {/*            <div key={index} className="w-100 h-100 image-popup-item text-center">*/}
            {/*              <img className="h-100 w-100" key={index} src={item} alt="" />*/}
            {/*            </div>*/}
            {/*          )*/}
            {/*        })*/}
            {/*      }*/}
            {/*    </Slider>*/}
            {/*    {renderBtnNextPrev()}*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
          <div className="close-btn" onClick={handleClosePopup}>
            <img src='/icons/icClose.svg' className="close-btn-icon" alt="" />
          </div>
        </div>
      </div>
    </>
  )
}

export default GalleryPopup;
