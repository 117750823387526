import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TopNav from '../../components/top-nav';
import Loading from '../../components/loading';
import _3dSettings from '../../apis/api/_3dSettings';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import './index.scss';

import { reqSetSelectedUnit, reqIsShowGallery, reqIsShowFloorplan, reqSetIsShowPrecinctDetail } from '../../reduxs/unit-explore/action';
import { reqSetExploreModal, reqSetIsShowExploreModal } from '../../reduxs/explore-modal/action';
import {
  reqSetIsExpandNav,
  reqSetPage,
  reqSetActiveTransportOption,
  reqSetActiveTransportOptionDistricts,
  reqSetIsShowEndGuideTenantSession,
  reqSetIsShowFuture,
  reqSetIsShowGuidePopup,
} from '../../reduxs/home/action';
import { PAGES, LAYERS } from '../../constants/options';
import { threePosition } from '../../helper/threeHeper';
import { reqSetIsShowPrecinctExploreDetail, reqSetIsShowReplayVideo } from '../../reduxs/precinct-explore/action';
import { reqSetActivePrecinctID } from '../../reduxs/transport-options/action';
import { reqSetIsShowGalleryModal } from '../../reduxs/district-future-detail/action';
import VideoIntro from '../../components/video-intro';
import Contact from '../../components/contact';
import HotspotModal from '../../components/hotspot-modal';
import About from '../../components/about';
import HomeGallery from '../../components/home-gallery';
import GalleryPopup from '../../components/gallery-popup';
import LegendSidebar from '../../components/legend-sidebar';
import GuidePopup from '../../components/guide-popup';
import { useEffect } from 'react';
import VirtualGallery from '../../components/virtual-gallery';
import IntroFutureBtn from '../../components/guide-popup/intro-future-btn';

const ReactUI = (props) => {
  const { setIsIntroduction, controls, refScene, setActiveObjectIds, resetActiveColorModel } = props;
  const dispatch = useDispatch();

  const page = useSelector((state) => state.home.page);
  const isNavExpand = useSelector((state) => state.home.isNavExpand);
  const isShowFuture = useSelector((state) => state.home.isShowFuture);
  const isShowHotspotModal = useSelector((state) => state.home.isShowHotspotModal);
  const modal = useSelector((state) => state.exploreModal.modal);

  const pagesSettings = useSelector((state) => state.scene.pagesSettings);
  const isLoading = useSelector((state) => state.scene.isLoading);
  const transportOptionDistricts = useSelector((state) => state.home.transportOptionDistricts);
  const isShowGalleryModal = useSelector((state) => state.home.isShowHotspotalleryModal);
  const isShowGuidePopup = useSelector((state) => state.home.isShowGuidePopup);
  const isShowIntroFutureBtn = useSelector((state) => state.home.isShowIntroFutureBtn);

  useEffect(() => {
    if (page == PAGES.LANDING_PAGE) {
      setTimeout(() => {
        dispatch(reqSetIsShowGuidePopup(true));
      }, 2000)
    }
  }, [page]);

  const HandleSetActivePage = React.memo((props) => {
    if (props.pagesSettings == null) {
      return <div />;
    }
    const settings = props.pagesSettings.filter(setting => setting.name == page);
    if (settings.length == 0) {
      return <div />;
    }
    if (controls.current == null || controls.current.currentPage == page) {
      return <div />;
    }
    controls.current.currentPage = page;
    const setting = settings[0];

    setting.active_objects != null && setting.active_objects.length > 0 && setActiveObjectIds(setting.active_objects);

    if (setting.camera && setting.camera_look_at_position) {
      const camPosition = threePosition(setting.camera);
      const camLookAtPosition = threePosition(setting.camera_look_at_position);
      controls.current.lookAtAndMovePosition(camLookAtPosition, camPosition, setting.duration ?? 2, () => {
        controls.current.state = -1;
      });
    }

    if (Array.isArray(setting.show_layers) &&
      Array.isArray(setting.enable_layers)) {
      controls.current.hideAll();
      controls.current.disableAll();

      controls.current.showAndEnableLayer(LAYERS.NONE);

      for (let i = 0; i < setting.show_layers.length; i += 1) {
        controls.current.showLayer(setting.show_layers[i]);
      }
      for (let i = 0; i < setting.enable_layers.length; i += 1) {
        controls.current.enableLayer(setting.enable_layers[i]);
      }

      if (isShowFuture) {
        controls.current.showAndEnableLayer(LAYERS.FUTURE);
      } else {
        controls.current.showAndEnableLayer(LAYERS.EXISTING);
      }
    }
    return <div />
  });
  HandleSetActivePage.displayName = 'HandleSetActivePage';

  const handleClickCube = () => {
    resetState();
    dispatch(reqSetIsExpandNav(!isNavExpand));
  };

  const handleClickInteractiveTour = () => {
    resetState();
    dispatch(reqSetPage(PAGES.INTERACTIVE_TOUR));
  }

  const handleClickGallery = () => {
    resetState();
    dispatch(reqSetPage(PAGES.GALLERY_PAGE));
  };

  const handleClickGuidedTour = () => {
    resetState();
    dispatch(reqSetPage(PAGES.GUIDED_TOUR_PAGE));
  };

  const handleClickContact = () => {
    resetState();
    dispatch(reqSetPage(PAGES.CONTACT_PAGE));
  };

  const handleClickVirtualGallery = () => {
    resetState();
    dispatch(reqSetPage(PAGES.VIRTUAL_GALLERY));
  };

  const handleClickAbout = () => {
    resetState();
    dispatch(reqSetPage(PAGES.ABOUT_PAGE));
  };

  const handleClickFuture = () => {
    controls.current.hideLayer(LAYERS.EXISTING);
    controls.current.showAndEnableLayer(LAYERS.FUTURE);
    dispatch(reqSetIsShowFuture(true));
  };

  const handleClickPresent = () => {
    controls.current.hideLayer(LAYERS.FUTURE);
    controls.current.showAndEnableLayer(LAYERS.EXISTING);
    dispatch(reqSetIsShowFuture(false));
  };

  const handleClickLogo = () => {
    dispatch(reqSetPage(PAGES.ONBOARD_PAGE));
  }

  const resetState = () => {

    if (controls.current != null) {
      controls.current.needReloadSelectedHotspotId = true;

    }

    if (refScene.current != null) {
      transportOptionDistricts.forEach((tp) => {
        tp.hidden_when_not_selected.forEach(id => {
          let object = refScene.current.getObjectByName(id);
          if (object != null) {
            object.layers.set(object.userData.layer ?? LAYERS.DISABLE);
          }
        })
      });
    }

    resetActiveColorModel({});
    setActiveObjectIds([]);
    dispatch(reqSetActiveTransportOption([]));
    dispatch(reqSetActiveTransportOptionDistricts([]));
    dispatch(reqSetSelectedUnit(''));
    dispatch(reqIsShowGallery(false));
    dispatch(reqSetIsShowExploreModal(false));
    dispatch(reqIsShowFloorplan(false));
    dispatch(reqSetIsShowPrecinctExploreDetail(false));
    dispatch(reqSetIsShowPrecinctDetail(false));
    dispatch(reqSetPage(PAGES.LANDING_PAGE));
    dispatch(reqSetActivePrecinctID(null));
    dispatch(reqSetIsShowEndGuideTenantSession(false));
    dispatch(reqSetIsShowReplayVideo(false));
    dispatch(reqSetIsShowExploreModal(false));
    dispatch(reqSetIsShowGalleryModal(false));
    dispatch(reqSetExploreModal(undefined));
  };

  return (
    <>
      <HandleSetActivePage pagesSettings={pagesSettings} page={page} />
      <TopNav
        isShow={![PAGES.ONBOARD_PAGE].includes(page)}
        isLoading={isLoading}
        handleClickGuidedTour={handleClickGuidedTour}
        handleClickAbout={handleClickAbout}
        handleClickContact={handleClickContact}
        handleClickCube={handleClickCube}
        handleClickGallery={handleClickGallery}
        handleClickLogo={handleClickLogo}
        handleClickInteractiveTour={handleClickInteractiveTour}
        handleClickVirtualGallery={handleClickVirtualGallery}
      />
      {page == PAGES.ONBOARD_PAGE && (
        <Loading
          isLoading={isLoading}
          setIsIntroduction={setIsIntroduction}
        />
      )}
      <CSSTransition in={page !== PAGES.ONBOARD_PAGE} timeout={1000} classNames="fade-item" unmountOnExit>
        <div className={`wrap-bottom-titles`} style={{zIndex: isShowIntroFutureBtn ? 1051 : 1000}}>
          {(page == PAGES.LANDING_PAGE || page == PAGES.INTERACTIVE_TOUR) &&
            <>
              {
                isShowFuture
                  ?
                  <button onClick={handleClickPresent} className="btn-show-future">
                    <img className='img-fluid' src="/icons/exist_btn.svg" alt="" />
                  </button>
                  :
                  <button onClick={handleClickFuture} className="btn-show-future">
                    <img className='img-fluid' src="/icons/future_btn.svg" alt="" />
                  </button>
              }
            </>
          }
        </div>
      </CSSTransition>

      {
        isShowIntroFutureBtn && (
          <TransitionGroup>
            <CSSTransition in={isShowIntroFutureBtn} timeout={200} classNames="fade-item" unmountOnExit>
              <IntroFutureBtn />
            </CSSTransition>
          </TransitionGroup>
        )
      }


      {
        ([PAGES.LANDING_PAGE, PAGES.INTERACTIVE_TOUR, PAGES.VIRTUAL_GALLERY].includes(page) && isShowHotspotModal && modal != undefined && modal.type == 'virtual') ?
          <TransitionGroup>
            <CSSTransition timeout={200} classNames="fade-item">
              <HotspotModal />
            </CSSTransition>
          </TransitionGroup> : <div />
      }

      <TransitionGroup>
        {
          page == PAGES.CONTACT_PAGE && (
            <CSSTransition
              timeout={500}
              classNames="fade-item"
              unmountOnExit
            >
              <Contact />
            </CSSTransition>
          )
        }
      </TransitionGroup>
      <TransitionGroup>
        {
          page == PAGES.ABOUT_PAGE && (
            <CSSTransition
              timeout={500}
              classNames="fade-item"
              unmountOnExit
            >
              <About />
            </CSSTransition>
          )
        }
      </TransitionGroup>
      <TransitionGroup>
        {
          (page == PAGES.GALLERY_PAGE) && (
            <CSSTransition
              timeout={500}
              classNames="fade-item"
              unmountOnExit
            >
              <HomeGallery />
            </CSSTransition>
          )
        }
      </TransitionGroup>
      <TransitionGroup>
        {
          page == PAGES.GUIDED_TOUR_PAGE && (
            <CSSTransition
              timeout={500}
              classNames="fade-item"
              unmountOnExit
            >
              <VideoIntro />
            </CSSTransition>
          )
        }
      </TransitionGroup>

      {
        ([PAGES.LANDING_PAGE, PAGES.INTERACTIVE_TOUR] && isShowGalleryModal) ? (
          <TransitionGroup>
            <CSSTransition
              timeout={50}
              classNames="fade-item"
              unmountOnExit
            >
              <GalleryPopup />
            </CSSTransition>
          </TransitionGroup>
        ) : <div />
      }
      {
        (page == PAGES.INTERACTIVE_TOUR) && (
          <TransitionGroup>
            <CSSTransition timeout={5000} classNames="fade-item" unmountOnExit>
              <LegendSidebar />
            </CSSTransition>
          </TransitionGroup>
        )
      }

      <TransitionGroup>
        {
          (page == PAGES.LANDING_PAGE && isShowGuidePopup) && (
            <CSSTransition
              timeout={50}
              classNames="fade-item"
              unmountOnExit
            >
              <GuidePopup />
            </CSSTransition>
          )
        }
      </TransitionGroup>

      <TransitionGroup>
        {
          (page == PAGES.VIRTUAL_GALLERY) && (
            <CSSTransition
              timeout={500}
              classNames="fade-item"
              unmountOnExit
            >
              <VirtualGallery />
            </CSSTransition>
          )
        }
      </TransitionGroup>
    </>
  );
};

export default ReactUI;

