import React from "react";
import { useDispatch } from "react-redux";
import { reqSetIsShowIntroFutureBtn } from "../../reduxs/home/action";

const IntroFutureBtn = () => {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(reqSetIsShowIntroFutureBtn(false));
  }

  return (
    <div className="bg-trim" onClick={onClose}>
      <div className="wrap-intro-future-btn">
        <div className='divider'/>
        <span>Click this button to see our vision for the future of Diriyah</span>
      </div>
    </div>
  )
}

export default IntroFutureBtn;
