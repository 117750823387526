import React from "react";
import { useDispatch } from "react-redux";
import { PAGES } from "../../constants/options";
import { reqSetIsShowGuidePopup, reqSetIsShowIntroFutureBtn, reqSetPage } from "../../reduxs/home/action";
import './index.scss';
import { BrowserView, MobileView } from 'react-device-detect';

const GuidePopup = () => {
  const dispatch = useDispatch();

  const handleClosePopup = () => {
    dispatch(reqSetIsShowGuidePopup(false))
    dispatch(reqSetPage(PAGES.INTERACTIVE_TOUR));
    dispatch(reqSetIsShowIntroFutureBtn(true));
  }

  return (
    <>
      <div className="bg-trim" />
      <div className="wrap-guide-popup">
        <div className="popup-body">
          <div className="wrap-intro-description">
            <div className="intro-description">
              <h4 className="title">THE CITY OF EARTH</h4>
              <div className="description">Welcome to the first chapter of our history. Here you can experience the wonders of Diriyah.</div>
              <div onClick={handleClosePopup} className="btn-explore">
                <span>
                  DISCOVER NOW
                </span>
                <img src="./icons/arrow_gray.svg" alt="" />
              </div>
            </div>
            <div className='pattern'>
              <img  src="./icons/pattern.svg" alt="" />
            </div>
          </div>
          <div className="wrap-intro-action">
            <div className="desktop show-desktop">
              <div className="item">
                <div className="icon">
                  <img className="img-fluid" src="./icons/icon_1.svg" alt="" />
                </div>
                <div className="text">Drag left/right to rotate</div>
              </div>
              <div className="item">
                <div className="icon">
                  <img className="img-fluid" src="./icons/icon_2.svg" alt="" />
                </div>
                <div className="text">Scroll up/down to zoom</div>
              </div>
            </div>
            <div className="desktop show-mobile">
              <div className="item">
                <div className="icon">
                  <img className="img-fluid" src="./icons/icon_3.svg" alt="" />
                </div>
                <div className="text">Slide two fingers to rotate</div>
              </div>
              <div className="item">
                <div className="icon">
                  <img className="img-fluid" src="./icons/icon_4.svg" alt="" />
                </div>
                <div className="text">Pinch to zoom</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default GuidePopup;
