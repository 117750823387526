import React from 'react';
import { useState } from 'react';
import './index.scss';
import { PAGES } from '../../constants/options';
import { useEffect } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {useDispatch, useSelector} from 'react-redux';
import Cube from '../cube';
import {reqSetIsShowHotspotModal} from "../../reduxs/home/action";

library.add(faSearch);

const TopNav = (props) => {
  const {
    isShow,
    handleClickCube,
    handleClickGallery,
    handleClickContact,
    handleClickGuidedTour,
    handleClickLogo,
    handleClickInteractiveTour,
    handleClickVirtualGallery,
  } = props;
  const [isCollapse, setIsCollapse] = useState(true);
  const [firstShow, setFirstShow] = useState(true);
  const [isDragDown, setIsDragDown] = useState(false);
  const [isHideCubeOnDrag, setIsHideCubeOnDrag] = useState(true);
  const pageActive = useSelector((state) => state.home.page);
  const isShowHotspotModal = useSelector((state) => state.home.isShowHotspotModal);
  const [linksDisabled, setLinksDisabled] = useState(true);
  const dispatch = useDispatch();

  const setClickTimer = (localCollapsed) => {
    if (localCollapsed) {
      setLinksDisabled(true);
      return;
    }
    setTimeout(() => {
      setLinksDisabled(false);
    }, 700);
  }

  const onClickCube = () => {
    handleClickCube();
    setIsCollapse(false);
    setClickTimer(false);
  };

  useEffect(() => {
    isShow && onClickCube();
  }, [isShow]);

  const startDrag = () => {
    setIsHideCubeOnDrag(false);
  };

  const endDrag = () => {
    setIsHideCubeOnDrag(true);
    setIsDragDown(true);
    setFirstShow(false);
    setIsCollapse(!isCollapse);
    setClickTimer(!isCollapse);
  };

  const isActive = (menu) => {
    return pageActive === menu ? 'active' : '';
  };

  const handleHideVirtual = () => {
    dispatch(reqSetIsShowHotspotModal(false));
  }

  return (
    <div
      className={`wrap-top-nav
      ${isCollapse ? 'hide' : 'show'}`}
    >
      <div className='header-logo'>
        <img  onClick={pageActive !== PAGES.ONBOARD_PAGE ? handleClickLogo : {}} className='' src="/images/icLogo.svg" alt="" />
      </div>
      {pageActive !== PAGES.ONBOARD_PAGE && (
        <div className={`container nav-menu ${linksDisabled && 'disabled'}`}>
          {/* <div className='wrap-logo' onClick={handleClickLogo}>
          <img className='logo' src="/images/logo.svg" alt="" />
        </div> */}
          <ul className="left">
            <li className={isActive(PAGES.INTERACTIVE_TOUR)} onClick={handleClickInteractiveTour}>
              <span>DISCOVER DIRIYAH</span>
            </li>
            <li className={isActive(PAGES.GUIDED_TOUR_PAGE)} onClick={handleClickGuidedTour}>
              <span>SEE OUR STORY</span>
            </li>
          </ul>
          {/*<ul className="nav-cube">*/}
          {/*  <div className='logo' onClick={handleClickLogo}>*/}
          {/*    <img className='' src="/images/logo.svg" alt="" />*/}
          {/*  </div>*/}
          {/*  /!* {isShow && <Cube*/}
          {/*  draggable={true}*/}
          {/*  active={!isCollapse}*/}
          {/*  onDragStart={startDrag}*/}
          {/*  onDragEnd={isShow && endDrag}*/}
          {/*  onClick={isShow && onClickCube}*/}
          {/*  onDoubleClick={() => isShow && handleClickCube()}*/}
          {/*/>} *!/*/}
          {/*</ul>*/}
          <ul className="right">
            <li className={isActive(PAGES.GALLERY_PAGE)} onClick={handleClickGallery}>
              <span>LIVE</span>
            </li>
            {/* <li className={isActive(PAGES.CONTACT_PAGE)} onClick={handleClickContact}>
            <span>ENQUIRE NOW</span>
          </li> */}
            <li className={isActive(PAGES.VIRTUAL_GALLERY)} onClick={handleClickVirtualGallery}>
              <span>EXPLORE</span>
            </li>
          </ul>
          {isShowHotspotModal && (
            <button className="btn-go-back-virtual" onClick={handleHideVirtual}>
              <img src="/icons/arrow.svg" alt="" />
              <span >
               Back
             </span>
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default TopNav;
